
export default {
  props: {
    media: {
      type: Object,
      default: () => {
        return {}
      },
    },
    steps: {
      type: Array,
      default: () => {
        return []
      },
    },
    animate: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: String,
      default: 'original',
    },
    poster: {
      type: Object,
      default: () => {
        return null
      },
    },
    mouseFollow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tl: null,
      hideControls: true,
      mouseX: '50%',
      mouseY: '50%',
      showMouse: false,
      fullscreen: {
        iosNative: true,
      },
      options: {
        player: null,
        markers: {
          enabled: true,
          points: this.steps,
        },
        controls: [
          'play-large',
          'play',
          'progress',
          'current-time',
          'mute',
          'volume',
        ],
      },
    }
  },

  computed: {
    url() {
      if (this.$imagekit) {
        return (
          this.$imagekit.url({
            src: this.media.attributes.url,
            transformation: [
              {
                quality: '50',
                width: 1000,
              },
            ],
          }) + '#t=0.1'
        )
      } else {
        return this.media.attributes.url + '?tr=w-500,q-50#t=0.1'
      }
    },
    posterSrc() {
      if (this.poster && this.poster.mime.includes('image') && this.$imagekit) {
        return this.$imagekit.url({
          src: this.poster.url,
          transformation: [
            {
              width: 1500,
              quality: '70',
              dpr: 'auto',
              crop: 'fill',
            },
          ],
        })
      } else {
        return null
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.plyr.player.on('timeupdate', (event) =>
        this.$emit('timeupdate', event)
      )
      this.$refs.plyr.player.on('ready', (event) => this.playerReady(event))
      this.$refs.plyr.player.on('play', (event) => this.play(event))

      if (this.animate) {
        const mm = this.$gsap.matchMedia()

        mm.add('(min-width: 1024px)', () => {
          const vw = window.innerWidth
          const vh = window.innerHeight
          const inset = 'inset(' + vh * 0.2 + 'px ' + vw * 0.2 + 'px)'
          this.tl = this.$gsap
            .timeline({
              scrollTrigger: {
                trigger: this.$refs.wrapper,
                start: 'top bottom',
                end: '+=' + window.innerHeight * 2,
                scrub: true,
              },
            })
            .from(this.$refs.wrapper, {
              duration: 1,
              webkitClipPath: inset,
              clipPath: inset,
              ease: 'quad.inOut',
            })
            .to(this.$refs.wrapper, {
              duration: 1,
              webkitClipPath: inset,
              clipPath: inset,
              ease: 'quad.inOut',
            })
        })
      }
    }, 1000)
  },
  methods: {
    mouseEnter(event) {
      this.$el.addEventListener('mousemove', this.mouseMove, false)
      this.showMouse = true
    },
    mouseLeave(event) {
      this.$el.removeEventListener('mousemove', this.mouseMove)
      this.showMouse = false
    },
    mouseMove(event) {
      const viewportOffset = this.$refs.wrapper.getBoundingClientRect()
      this.mouseX = event.clientX - viewportOffset.left + 'px'
      this.mouseY = event.clientY - viewportOffset.top + 'px'
    },
    goto(time) {
      this.$refs.plyr.player.currentTime = time
    },
    play(event) {
      if (this.tl) this.tl.kill()
      const instance = event.detail.plyr
      this.hideControls = false
      this.$gsap.to(this.$refs.wrapper, {
        duration: 1,
        webkitClipPath: 'inset(0px 0px)',
        clipPath: 'inset(0px 0px)',
        ease: 'quad.inOut',
      })
      if (
        this.$refs.wrapper &&
        this.$refs.wrapper.getElementsByClassName('plyr__poster')[0]
      ) {
        this.$gsap.to(
          this.$refs.wrapper.getElementsByClassName('plyr__poster')[0],
          {
            duration: 0.3,
            opacity: 0,
            ease: 'quad.inOut',
          }
        )
      }
      const players = Array.from(
        document.querySelectorAll('.plyr__video-wrapper')
      )

      players.forEach(function (p) {
        if (
          p.children[0].plyr !== instance &&
          !p.children[0].hasAttribute('autoplay')
        )
          p.children[0].plyr.stop()
      })
    },
    playerReady(event) {
      this.player = event.detail.plyr
      this.player.toggleControls(false)

      if (this.poster) {
        let poster = null
        if (this.poster.mime.includes('video')) {
          const path = this.$refs.wrapper.getElementsByClassName('plyr__poster')
          poster = path[0]
          poster.classList.add('is-video')
          poster.innerHTML =
            '<div class="video-poster"><video src="' +
            this.poster.url +
            '" muted loop autoplay playsinline></video></div>'
        }
      }
    },
  },
}
