
import utils from '@/mixins/utils'
export default {
  mixins: [utils],
  data() {
    return {
      intersectionOptions: {
        root: null,
        rootMargin: '0% 0% -50% 0%',
        threshold: 0,
      },
    }
  },
  methods: {
    onWaypoint({ going, direction }) {
      if (
        going === this.$waypointMap.GOING_IN &&
        direction === this.$waypointMap.DIRECTION_TOP
      ) {
        this.$store.commit('setColormode', 'dark')
      }
      if (
        going === this.$waypointMap.GOING_OUT &&
        direction === this.$waypointMap.DIRECTION_BOTTOM
      ) {
        this.$store.commit('setColormode', 'light')
      }
    },
  },
}
