
import utils from '@/mixins/utils'
import Standfirst from '@/components/Standfirst'
import Hero from '@/components/Hero'
export default {
  mixins: [utils],

  components: {
    Standfirst,
    Hero,
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      },
    },
  },
}
