
import Media from '@/components/Media'
import OpeningHours from '@/components/OpeningHours'
import utils from '@/mixins/utils'

export default {
  mixins: [utils],

  components: {
    Media,
    OpeningHours,
  },

  watch: {
    $route(to, from) {
      this.mouseout()
    },
  },

  data() {
    return {
      load: false,
    }
  },

  computed: {
    menu() {
      return this.$store.state.menu
    },
  },
  mounted() {
    setTimeout(() => {
      this.load = true
      this.$store.commit('setSubmenusLoaded')
    }, 1000)
  },
  methods: {
    onClick() {
      this.mouseout()
    },
    submenuover(event) {
      if (this.load) {
        this.$store.commit('setSubmenuHover', event.target.dataset.id)
      }
    },
    submenuout(event) {
      if (this.load) {
        event.stopPropagation()
        this.$store.commit('setSubmenuHover', null)
      }
    },
    mouseout(event) {
      if (this.load) {
        if (event) {
          event.stopPropagation()
        }
        this.$store.commit('setMenuHover', null)

        this.$gsap.to('.submenus-bg', {
          duration: 0.7,
          height: 0,
          ease: 'expo.inOut',
        })
      }
    },
  },
}
