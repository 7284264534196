export const state = () => ({
  products: [],
  client: null,
  ui: null,
  country: null,
  buybutton: null
})

export const getters = {
  getProduct: (state) => (sku) => {
    return state.products.find(product => product.variants[0].sku === sku)
  },
  getBuyButton: (state) => (buybuttons) => {
    return buybuttons.find(buybutton => buybutton.country === state.country)
  },
}

export const mutations = {
  setProducts(state, products) {
    state.products = products
  },
  setClient(state, client) {
    state.client = client
  },
  setCountry(state, data) {
    state.country = data
  },
  setBuyButton(state, data) {
    state.buybutton = data
  },
}

export const actions = {
  buildClient({ commit, dispatch, state }) {
    if (window && window.ShopifyBuy) {
      const client = window.ShopifyBuy.buildClient({ domain: state.buybutton.domain, storefrontAccessToken: state.buybutton.token })
      if (window.ui) {
        Object.keys(window.ui.components).forEach((type) => {
          window.ui.components[type].forEach((component) => {
            component.destroy()
          });
          window.ui.components[type] = [];
        });
      } 
      window.ui = window.ShopifyBuy.UI.init(client)
      commit('setClient', client)
      dispatch('fetchProducts')
    }
  },
  async fetchProducts({ commit, state }) {
    await state.client.product.fetchAll(50).then((products) => {
      commit('setProducts', products)
    });
  },
}