
import Modules from '@/components/Modules'
import Footer from '@/components/Footer'
import PageTop from '@/components/pages/PageTop'

export default {
  props: ['error'],
  layout: 'default',
  components: {
    Modules,
    PageTop,
    Footer,
  },
  computed: {
    page() {
      return this.$store.state.errorPage
    },
  },
}
