export const articles = `
  data {
  id
    attributes {
      publishedAt
      slug
      title
      description
      media {
        data {
          attributes {
            width
            height
            caption
            mime
            url
            hash
            ext
          }
        }
      }
      article_categories {
        data {
          id
        }
      }
    }
  }
`;