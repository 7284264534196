import gql from "graphql-tag";
import { articles } from '~/graphql/subqueries/articles'
import { products } from '~/graphql/subqueries/products'
import { events } from '~/graphql/subqueries/events'
import { recipes } from '~/graphql/subqueries/recipes'
import { pages } from '~/graphql/subqueries/pages'
import { articleCategories } from '~/graphql/subqueries/articleCategories'
import { productCategories } from '~/graphql/subqueries/productCategories'
import { eventCategories } from '~/graphql/subqueries/eventCategories'
import { recipeTypes } from '~/graphql/subqueries/recipeTypes'
import { recipeMethods } from '~/graphql/subqueries/recipeMethods'
import { stores } from '~/graphql/subqueries/stores'
import { storeTypes } from '~/graphql/subqueries/storeTypes'
export const teasersQuery = gql`
  query Teasers {
    articles(sort: "id:desc", pagination: { limit: 100 }) {
      ${articles}
    }
    articleCategories(sort: "title:ASC", pagination: { limit: 100 }) {
      ${articleCategories}
    }
    products(sort: "rank:asc", pagination: { limit: 100 }) {
      ${products} 
    }
    productCategories(sort: "title:ASC", pagination: { limit: 100 }) {
      ${productCategories} 
    }
    events(sort: "date:DESC", pagination: { limit: 100 }) {
      ${events}
    }
    eventCategories(pagination: { limit: 100 }) {
      ${eventCategories}
    }
    recipes(pagination: { limit: 100 }) {
      ${recipes}
    }  
    recipeTypes(sort: "title:ASC", pagination: { limit: 100 }) {
      ${recipeTypes}
    }  
    recipeMethods(sort: "title:ASC", pagination: { limit: 100 }) {
      ${recipeMethods}
    }  
    pages(pagination: { limit: 100 }) {
      ${pages}
    }  
    stores(sort: "title:ASC", pagination: { limit: 1000 }) {
      ${stores}
    }  
    storeTypes(sort: "title:ASC", pagination: { limit: 100 }) {
      ${storeTypes}
    }  
  }
`