
import Vue from 'vue'
import VScrollLock from 'v-scroll-lock'
import { Expo } from 'gsap'
import utils from '@/mixins/utils'
import Logo from '@/components/Logo'
import LanguageSwitcher from '@/components/LanguageSwitcher'
import Cart from '@/components/shop/Cart'
import CurrencySwitcher from '@/components/shop/CurrencySwitcher'

Vue.use(VScrollLock)
export default {
  mixins: [utils],
  components: {
    Logo,
    LanguageSwitcher,
    CurrencySwitcher,
    Cart,
  },

  data() {
    return {
      open: false,
      showNavbar: false,
      lastScrollPosition: 0,
    }
  },

  mounted() {
    this.tl = this.$gsap.timeline({
      paused: true,
    })

    this.tl.from('header', 0.4, {
      opacity: 0,
      top: '-30px',
      ease: Expo.easeOut,
    })
    this.tl.progress(1, false)
    this.$nuxt.$on('animateHeader', (forward) => {
      this.tl.reversed(!forward).resume()
    })

    // const self = this
    window.addEventListener('scroll', this.onScroll)
    // window.addEventListener('keydown', function (event) {
    //   const key = event.key
    //   switch (key) {
    //     case '!':
    //       event.preventDefault()
    //       self.toggleLanguage()
    //       break
    //   }
    // })
  },
  computed: {
    menu() {
      return this.$store.state.menu
    },
  },
  methods: {
    // toggleLanguage(event) {
    //   this.$store.commit('setShowLanguages', !this.$store.state.showLanguages)
    // },
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
    },
    toggle() {
      this.$store.dispatch('toggleMenu')
    },
    mouseover(event) {
      if (this.$store.state.submenusLoaded) {
        this.$store.commit('setMenuHover', parseInt(event.target.dataset.id))
        this.$gsap.to('.submenus-bg', {
          height: document.querySelector(
            '.submenu[data-id="' + event.target.dataset.id + '"]'
          ).offsetHeight,
          duration: 0.7,
          opacity: 1,
          ease: 'expo.out',
        })
      }
    },
  },
}
