var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.$store.state.scrollLocked),expression:"$store.state.scrollLocked"}],staticClass:"app",class:[
    { 'show-announcement': _vm.$store.state.info.announcement.show },
    _vm.$store.state.colormode,
    {
      consent: _vm.$store.state.consent,
      'show-consent-error': _vm.$store.state.consentError,
    },
  ]},[(_vm.$store.state.info.announcement.show)?_c('Announcement'):_vm._e(),_vm._v(" "),_c('MobileMenu'),_vm._v(" "),_c('Header'),_vm._v(" "),_c('Submenus'),_vm._v(" "),_c('div',{attrs:{"id":"cart"}}),_vm._v(" "),_c('transition',{attrs:{"name":"page","mode":"out-in"},on:{"afterLeave":_vm.afterLeave}},[_c('Nuxt',{key:_vm.$route.path,class:{ 'menu-open': _vm.$store.state.showMenu },attrs:{"nuxt-child-key":_vm.routerViewKey}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }