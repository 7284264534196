
import VLazyImage from 'v-lazy-image/v2/v-lazy-image.es.js'
export default {
  components: {
    VLazyImage,
  },
  data() {
    return {
      widths: [500, 800, 1200, 1500, 2000],
      src: this.getUrl(1500),
      loaded: false,
    }
  },
  props: {
    image: {
      type: Object,
      default: () => {
        return null
      },
    },
    showCaption: {
      type: Boolean,
      default: false,
    },
    bg: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: String,
      default: null,
    },
    preload: {
      type: Boolean,
      default: true,
    },
    carousel: {
      type: Boolean,
      default: false,
    },
    animation: {
      type: String,
      default: null,
    },
    desktopSize: {
      type: String,
      default: '100vw',
    },
    mobileSize: {
      type: String,
      default: '100vw',
    },
  },
  computed: {
    srcset() {
      let srcset = ''

      for (let i = 0; i < this.widths.length; i++) {
        srcset += this.getUrl(this.widths[i]) + ' ' + this.widths[i] + 'w, '
      }
      return srcset
    },
    aspect() {
      if (this.aspectRatio && this.aspectRatio.includes(':')) {
        return this.aspectRatio.split(':')
      } else if (this.image) {
        return [this.image.attributes.width, this.image.attributes.height]
      } else {
        return [1, 1]
      }
    },
    width() {
      return 500
    },
    height() {
      return 500 * (this.aspect[1] / this.aspect[0])
    },
    sizes() {
      return (
        '(max-width: 1023px) ' +
        this.mobileSize +
        ', (min-width: 1024px) ' +
        this.desktopSize
      )
    },
  },
  mounted() {
    if (this.animation) {
      const vw = window.innerWidth
      // const vh = window.innerHeight
      let inset = 'inset(0)'
      if (this.animation === 'left') {
        inset = 'inset(0 ' + vw * 0.2 + 'px 0 0)'
      }
      if (this.animation === 'right') {
        inset = 'inset(0 0 0 ' + vw * 0.2 + 'px)'
      }

      this.tl = this.$gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$refs.picture,
            start: 'top bottom',
            end: '+=' + window.innerHeight * 1.5,
            scrub: true,
          },
        })
        .from(this.$refs.picture, {
          duration: 1,
          webkitClipPath: inset,
          clipPath: inset,
          ease: 'expo.out',
        })
    }
  },
  methods: {
    onLoad() {
      this.loaded = true
    },
    getUrl(width) {
      if (this.image && this.image.attributes.mime === 'image/gif') {
        return this.image.attributes.url
      } else if (this.$imagekit) {
        return this.$imagekit.url({
          src: this.image.attributes.url,
          transformation: [
            {
              height:
                this.aspectRatio &&
                this.aspectRatio.includes(':') &&
                Array.isArray(this.aspect)
                  ? Math.ceil(width / (this.aspect[0] / this.aspect[1]))
                  : null,
              width,
              quality: '70',
              dpr: 'auto',
              crop: 'fill',
            },
          ],
        })
      } else {
        return ''
      }
    },
  },
}
