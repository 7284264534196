import Vue from 'vue'
import {
  MglMap,
  MglGeolocateControl,
  MglMarker,
  MglNavigationControl,
  MglFullscreenControl,
  MglGeojsonLayer
} from 'vue-mapbox/dist/vue-mapbox.umd.js'
import Mapbox from 'mapbox-gl'

Vue.component('MglMap', MglMap)
Vue.component('MglGeolocateControl', MglGeolocateControl)
Vue.component('MglGeojsonLayer', MglGeojsonLayer)
Vue.component('MglMarker', MglMarker)
Vue.component('MglNavigationControl', MglNavigationControl)
Vue.component('MglFullscreenControl', MglFullscreenControl)

Vue.prototype.$mapbox = Mapbox
