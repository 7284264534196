export const seo = `seo {
  metaTitle
  metaDescription
  metaImage {
    data {
      attributes {
        width
        height
        caption
        mime
        url
        hash
        ext
      }
    }
  }
}`;