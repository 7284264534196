
import utils from '@/mixins/utils'
export default {
  mixins: [utils],

  props: {
    expandable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      open: false,
    }
  },
  methods: {
    toggle() {
      this.open = !this.open
    },
  },
}
