
import { Expo } from 'gsap'
export default {
  props: {
    isIndex: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      intersectionOptions: {
        root: null,
        rootMargin: '0% 0% -5% 0%',
        threshold: 0,
      },
      tl: null,
    }
  },
  mounted() {
    const mm = this.$gsap.matchMedia()
    const breakPoint = 800

    mm.add(
      {
        isDesktop: `(min-width: ${breakPoint}px)`,
        isMobile: `(max-width: ${breakPoint - 1}px)`,
      },
      (context) => {
        const { isDesktop } = context.conditions
        // if (
        //   this.$refs.content &&
        //   (this.$refs.content.innerHTML.includes('h1') ||
        //     this.$refs.content.innerHTML.includes('h2')) &&
        //   !this.$route.query.preview
        // ) {
        //   this.tl = this.$gsap.timeline({ paused: true })
        //   const text = new this.$SplitText(
        //     this.$refs.content.querySelectorAll('h1,h2'),
        //     {
        //       type: 'lines',
        //     }
        //   )

        //   this.$gsap.set(this.$refs.content, { opacity: 1 })

        //   text.lines.forEach((line, index) => {
        //     this.tl.from(
        //       line,
        //       1 + 0.2 * index,
        //       {
        //         y: isDesktop ? '30%' : null,
        //         opacity: 0,
        //         delay: 0.1 * index,
        //         ease: Expo.easeOut,
        //       },
        //       this.isIndex ? 1 : 0
        //     )
        //   })
        // } else {
        this.tl = this.$gsap.timeline({ paused: true })
        this.$gsap.set(this.$refs.content, { opacity: 1 })

        this.tl.from(
          this.$refs.content,
          1,
          {
            y: isDesktop ? '30px' : null,
            opacity: 0,
            ease: Expo.easeOut,
          },
          0
        )
      }
      // }
    )
  },
  methods: {
    onWaypoint({ going, direction }) {
      if (
        going === this.$waypointMap.GOING_IN ||
        (going === this.$waypointMap.GOING_OUT &&
          direction === this.$waypointMap.DIRECTION_BOTTOM)
      ) {
        if (this.tl) this.tl.play()
      }
    },
  },
}
