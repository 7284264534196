
import utils from '@/mixins/utils'
import OpeningHours from '@/components/OpeningHours'
import LanguageSwitcher from '@/components/LanguageSwitcher'
import CurrencySwitcher from '@/components/shop/CurrencySwitcher'
export default {
  mixins: [utils],
  components: {
    OpeningHours,
    LanguageSwitcher,
    CurrencySwitcher,
  },
  computed: {
    menu() {
      return this.$store.state.menu
    },
  },
  watch: {
    '$store.state.showMenu'(value) {
      if (value) {
        const elements = this.$refs.first.querySelectorAll('.open')
        elements.forEach((element) => {
          element.classList.remove('open')
          element.classList.add('closed')
          const menu = element.parentElement.children[1]
          this.$gsap.set(menu, {
            ease: 'quart.inOut',
            height: 0,
          })
        })
      }
    },
  },
  methods: {
    onClick() {
      this.$store.dispatch('hideMenu')
    },
    toggle(event) {
      const title = event.target
      const parent = event.target.parentElement
      const menu = parent.children[1]
      const inner = menu.children[0]

      if (title.classList.contains('closed')) {
        title.classList.remove('closed')
        title.classList.add('open')
      } else {
        title.classList.remove('open')
        title.classList.add('closed')
      }
      this.$gsap.to(menu, {
        duration: 0.5,
        ease: 'quart.inOut',
        height: title.classList.contains('closed') ? 0 : inner.offsetHeight,
      })
    },
  },
}
