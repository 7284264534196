
const ModuleImages = () => import('@/components/modules/ModuleImages')
const ModuleText = () => import('@/components/modules/ModuleText')
const ModuleEmbed = () => import('@/components/modules/ModuleEmbed')
const ModuleSplitview = () => import('@/components/modules/ModuleSplitview')
const ModuleSlideshow = () => import('@/components/modules/ModuleSlideshow')
const ModuleArticles = () => import('@/components/modules/ModuleArticles')
const ModuleProducts = () => import('@/components/modules/ModuleProducts')
const ModuleEvents = () => import('@/components/modules/ModuleEvents')
const ModulePages = () => import('@/components/modules/ModulePages')
const ModuleStorelocator = () =>
  import('@/components/modules/ModuleStorelocator')
const ModuleRecipes = () => import('@/components/modules/ModuleRecipes')
const ModuleVideo = () => import('@/components/modules/ModuleVideo')
export default {
  components: {
    ModuleImages,
    ModuleText,
    ModuleSplitview,
    ModuleSlideshow,
    ModuleArticles,
    ModuleProducts,
    ModuleEvents,
    ModulePages,
    ModuleRecipes,
    ModuleStorelocator,
    ModuleVideo,
    ModuleEmbed,
  },
  props: {
    modules: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
