export const events = `
  data {
  id
    attributes {
      slug
      title
      tickets
      media {
        data {
          attributes {
            width
            height
            caption
            mime
            url
            hash
            ext
          }
        }
      }
      date
      description
      info
      event_category {
        data {
          id
        }
      }
    }
  }
`;