
import { Navigation, Mousewheel, Scrollbar } from 'swiper/modules'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import StrapiImage from '@/components/StrapiImage'
import VideoPlayer from '@/components/VideoPlayer'
import Vimeo from '@/components/Vimeo'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation.min.css'
import 'swiper/modules/scrollbar.min.css'
export default {
  components: {
    StrapiImage,
    VideoPlayer,
    Vimeo,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      },
    },
    showCaption: {
      type: Boolean,
      default: false,
    },
    carousel: {
      type: Boolean,
      default: false,
    },
    vimeo: {
      type: String,
      default: null,
    },
    preload: {
      type: Boolean,
      default: true,
    },
    aspectRatio: {
      type: String,
      default: 'original',
    },
    animation: {
      type: String,
      default: null,
    },
    lag: {
      type: Boolean,
      default: false,
    },
    desktopSize: {
      type: String,
      default: '100vw',
    },
  },
  data() {
    return {
      swiperOptions: {
        observer: true,
        observeParents: true,
        grabCursor: true,
        slidesPerView: 'auto',
        spaceBetween: 20,
        modules: [Navigation, Mousewheel, Scrollbar],
        scrollbar: {
          el: '#swiper-scrollbar-' + this._uid,
          draggable: true,
        },
        mousewheel: {
          forceToAxis: true,
        },
        navigation: {
          nextEl: '#swiper-button-next-' + this._uid,
          prevEl: '#swiper-button-prev-' + this._uid,
        },
        breakpoints: {
          768: {
            spaceBetween: 30,
          },
          1024: {
            spaceBetween: 30,
          },
        },
      },
    }
  },
  setup() {
    return {
      modules: [Navigation, Mousewheel, Scrollbar],
    }
  },
  methods: {
    handleClickSlide(index) {
      if (this.swiper) {
        this.swiper.slideTo(index, 500, false)
      }
    },
  },
}
