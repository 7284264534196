import gql from "graphql-tag";

export const infoQuery = gql`
  query Info {
    info {
      data {
        attributes {
          opening_hours
          announcement {
            text
            show
            background
            textcolor
            link
          }
        }
      }
    }
  }
`