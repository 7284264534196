export const products = `
  data {
  id
    attributes {
      slug
      title
      displaytitle
      info
      batch
      available
      cost
      sku
      description
      shop
      packshot {
        data {
          attributes {
            width
            height
            caption
            mime
            url
            hash
            ext
          }
        }
      }
      product_category {
        data {
          id
        }
      }
    }
  }
`;