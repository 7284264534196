export const recipes = `
    data {
    id
      attributes {
        slug
        title
        description
        media {
          data {
            attributes {
              width
              height
              caption
              mime
              url
              hash
              ext
            }
          }
        }
        products {
          data {
            id
          }
        }
        recipe_type {
          data {
            id
          }
        }
        recipe_method {
          data {
            id
          }
        }
      }
    }
`;