import { render, staticRenderFns } from "./Vimeo.vue?vue&type=template&id=2209ef34&scoped=true&"
import script from "./Vimeo.vue?vue&type=script&lang=js&"
export * from "./Vimeo.vue?vue&type=script&lang=js&"
import style0 from "./Vimeo.vue?vue&type=style&index=0&id=2209ef34&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2209ef34",
  null
  
)

export default component.exports