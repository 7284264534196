
export default {
  data() {
    return {
      open: false,
    }
  },
  head() {
    return {
      htmlAttrs: {
        'data-shipping': this.selectedShop
          ? `${this.selectedShop.country.toLowerCase()}`
          : null,
      },
    }
  },
  computed: {
    selectedShop() {
      return this.$store.state.shop.buybutton
    },
    otherShops() {
      if (this.selectedShop) {
        return this.$store.state.config.buybutton.filter(
          (shop) => shop.country !== this.selectedShop.country
        )
      } else {
        return []
      }
    },
  },
  methods: {
    toggle() {
      this.open = !this.open
    },
    select(country) {
      const buybutton = this.$store.state.config.buybutton.find(
        (shop) => shop.country === country
      )
      this.$store.commit('shop/setBuyButton', buybutton)
      this.$store.dispatch('shop/buildClient')
      this.open = false
    },
  },
}
