export const stores = `
  data {
  id
    attributes {
      title
      location
      link
      phone
      store_types {
        data {
          id
        }
      }
    }
  }
`;