import gql from "graphql-tag";

export const configQuery = gql`
  query Config {
    config {
      data {
        attributes {
          shop
          footer
          seo_image {
            data {
              attributes {
                width
                height
                caption
                mime
                url
                hash
                ext
              }
            }
          }
          seo_description
          buybutton {
            country
            domain
            token
            currency
          }
        }
      }
    }
  }
`