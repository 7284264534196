import { render, staticRenderFns } from "./MobileMenu.vue?vue&type=template&id=7762049b&scoped=true&"
import script from "./MobileMenu.vue?vue&type=script&lang=js&"
export * from "./MobileMenu.vue?vue&type=script&lang=js&"
import style0 from "./MobileMenu.vue?vue&type=style&index=0&id=7762049b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7762049b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LanguageSwitcher: require('/vercel/path0/components/LanguageSwitcher.vue').default,OpeningHours: require('/vercel/path0/components/OpeningHours.vue').default})
