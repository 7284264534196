import { render, staticRenderFns } from "./Submenus.vue?vue&type=template&id=7413a2bb&scoped=true&"
import script from "./Submenus.vue?vue&type=script&lang=js&"
export * from "./Submenus.vue?vue&type=script&lang=js&"
import style0 from "./Submenus.vue?vue&type=style&index=0&id=7413a2bb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7413a2bb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OpeningHours: require('/vercel/path0/components/OpeningHours.vue').default,Media: require('/vercel/path0/components/Media.vue').default})
