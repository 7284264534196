export const pages = `
  data {
  id
    attributes {
      slug
      title
      description
      media {
        data {
          attributes {
            width
            height
            caption
            mime
            url
            hash
            ext
          }
        }
      }
    }
  }
`;